export default [
  {
    index: 1,
    title: 'LG 38WK95C-W 38" Curved UltraWide WQHD+ Monitor',
    description: `I've really enjoyed the productivity boost of having a single ultrawide monitor with all the
       space I need to code, create content and edit media. I recently changed to this from a 27 inch
        LG 4k monitor and while the drop in resolution took some getting used to, I'm totally onboard with the trade-off.`,
    imageKey: 'monitorImage',
    link: 'https://amzn.to/2YXKmK7',
  },
  {
    index: 4,
    title: 'Geometric Abstract Wallpaper',
    description: `I've used this wallpaper for quite a while now. It's nice to look at without being too distracting. I also have the same
      wallpaper on my phone`,
    imageKey: 'wallpaperImage',
    link: 'https://wallpaperaccess.com/full/25566.jpg',
  },
  {
    index: 2,
    title: 'Anne Pro 2',
    description: `This Keyboard is the heart and soul of my workspace. Combined with the custom keycaps I've added it's a pleasure to look at and a pleasure to type with.
    I've had it for a couple months now and I absolutely enjoy typing on this thing every day.`,
    imageKey: 'keyboardImage',
    link: 'https://amzn.to/30Iz1Qs',
  },
  {
    index: 3,
    title: 'PBT Pudding Keycaps Set',
    description: `This relatively cheap pudding keycap set really shows off the RGB lights of the Anne Pro well. Nothing but good things to say about this. If you have an RGB keyboard, and like the white keycap aesthetic, you sould defintiely try this one.`,
    imageKey: 'keycapsImage',
    link: 'https://amzn.to/2XZZaZi',
  },
  {
    index: 5,
    title: 'Logitech MX Master 2s',
    description: `There's nothing I can say about the Logitech MX series that hasn't already been said. It's quite simply the best mouse I've ever used. When it comes to productivity and long hours of use, it's hard to beat this. Get the MX 3 though, unless you really want a white one like I did!`,
    imageKey: 'mouseImage',
    link: 'https://amzn.to/32zdESQ',
  },
  {
    index: 6,
    title: 'Oakywood Laptop Stand',
    description: `I love this stand because it is made from Walnut wood. It's also handcrafted and so each one is unique. It's nice to have a little natural texture in an otherwise all white and simple setup like mine.`,
    imageKey: 'laptopstandImage',
    link: 'https://amzn.to/2Ch1yD7',
  },
  {
    index: 7,
    title: 'Vivo Montior Desk Mount Stand',
    description: `Simple, functional, unobtrusive. Some of the words I use to describe this stand. It's cheap, gets the job done and while I initially bought it for my previous 27 inch monitor, it is just about strong enough to hold my current 38" Ultrawide`,
    imageKey: 'monitorstandImage',
    link: 'https://amzn.to/2CSANo8',
  },


  // {
  //   index: 8,
  //   title: 'Keychron K8',
  //   description: `This is my new daily driver. I still love the Anne Pro 2 and it's awesome clicky keys and 60% form factor, but during long coding sessions, the tactile brown switches on the Keychron feel much better for those marathon sessions where I'm doing a crap-ton of typing. I also love the extra keys on this tenkeyless board. Paired with the white pudding keycaps, it's an absolute treat to look at and code on`,
  //   imageKey: 'monitorstandImage',
  //   link: 'https://amzn.to/2CSANo8',
  // },
  // {
  //   index: 9,
  //   title: 'Divoom Ditoo',
  //   description: `The Divoom Ditoo is an awesome desk setup additon. It tells the time, has lovely pixel art (my personal favorites are the retro ones). It's got a respectable bluetooth speaker and it comes with nostalgic games such as Tetris. All while looking good! What's not to love!`,
  //   imageKey: 'monitorstandImage',
  //   link: 'https://amzn.to/2CSANo8',
  // },
  // {
  //   index: 10,
  //   title: 'JBL 305P Mark II',
  //   description: `I spent a ton of time researching what speakers I wanted for my setup. I considerd desktop models like the Audioengines, nice looking ones like the Edifiers but in the end I went with the JBLs for two reasons - One, awesome online reviews and praise for their flat, accurate studio monitoring profile and two - they came in white! First time I plugged these in and gave a listen, I damn near shed a tear. Everything sounds sooo much better now.`,
  //   imageKey: 'monitorstandImage',
  //   link: 'https://amzn.to/2CSANo8',
  // },
  // {
  //   index: 11,
  //   title: 'Logitech G305',
  //   description: `I love my MX Master 2s. Nothing comes close when I'm working or doing some editing. But when it's time to get some gaming done, they just too bulky and lack the response time to really be great. Enter the G305. I love these because they're white, they're super fast, light and they're wireless. Nothing but good things to say about these!`,
  //   imageKey: 'monitorstandImage',
  //   link: 'https://amzn.to/2CSANo8',
  // }, 
  // {
  //   index: 12,
  //   title: 'AVLT Dual Monitor Stand',
  //   description: `When it was time to get a second monitor, I needed a solution. I knew I wanted to stack my monitors but all the really solid options were in the hundreds of $$$. These come in at a fraction of the cost and do the job superpbly. Thought they're rated for 34" monitors, I have a 38" handing just fine on the bottom mount. They have almost no wobble and a respectable range of motion that suits me just fine.`,
  //   imageKey: 'monitorstandImage',
  //   link: 'https://amzn.to/2CSANo8',
  // },
  // {
  //   index: 13,
  //   title: 'HP Omen 165Hz Gaming Monitor',
  //   description: `I love this monitor. 27 glorious inches, 1440p, 165Hz refresh rate, a Nano IPS panel (made by LG) and a DCI-P3 compatibility of 98%. It's a content creation, gaming and media consumption beast. Colors are rendered really well, it's sharp and at 27inches & 1440p it's at the sweetspot of not being too taxing to drive my RTX 3070 while being pixel dense enough to not be pixelated. It's super bright too and I usually have it on 50% brightness for most tasks and for gaming. The main reason for me picking this was it's decent specs without having that 'gaming' monitor look`,
  //   imageKey: 'monitorstandImage',
  //   link: 'https://amzn.to/2CSANo8',
  // },
  // {
  //   index: 14,
  //   title: 'Splash Of Color Wallpaper',
  //   description: `I get asked about this wallpaper alot. I love how vibrant and saturated the colors are. I also love the contrast between the oranges/reds and the teals/blues. The smoky pattern ties it all together and the dark backdrop just makes it all pop. One of my all time faves!`,
  //   imageKey: 'monitorstandImage',
  //   link: 'https://amzn.to/2CSANo8',
  // },
];

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StyledLink from './common/styled-link';
import COLORS from '../constants/colors';

const SectionEntryTitleEl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.45rem;

  span.tags {
    font-size: 0.8rem;
    color: ${COLORS.baseColor};
    flex-shrink: 0;
    margin-left: 1rem;
    position: relative;
    color: ${COLORS.baseColor};

    span.tag {
      background: rgba(71, 180, 166, 0.32);
      padding: 3px 10px;
      border-radius: 4px;
      margin-right: 8px;
      display: inline-block;
      box-shadow: 3px 3px 4px rgba(71, 180, 0, 0.05);
    }
  }

  @media only screen and (max-width: 724px) {
    flex-direction: column;
    align-items: flex-start;

    span.tags {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
`;

const StyledTitle = styled.h3`
  font-size: ${({ small }) => (small ? '1rem' : '1.2rem')};
  position: relative;
  color: ${COLORS.baseColor};
  margin-bottom: 0;
  line-height: 2rem;
`;

const SectionEntryTitle = ({ title, tags, titleLink, small }) => {
  const isInternalLink = titleLink && titleLink[0] === '/';
  const conditionalLinkProps = {
    ...(!isInternalLink
      ? { target: '_blank', rel: 'noopener noreferrer' }
      : {}),
  };

  return (
    <SectionEntryTitleEl>
      {!titleLink ? (
        <React.Fragment>
          <StyledTitle small={small} isTitleLink={!!titleLink}>
            {title}
          </StyledTitle>
          {tags && (
            <span className="tags">
              {tags.map((tag) => (
                <span className="tag" key={tag}>
                  {tag}
                </span>
              ))}
            </span>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <StyledTitle small={small} isTitleLink={!!titleLink}>
            <StyledLink
              inheritTextStyle
              smallUnderline
              href={titleLink}
              alt="article-link"
              {...conditionalLinkProps}
            >
              {title}
            </StyledLink>
          </StyledTitle>
          {tags && (
            <span className="tags">
              {tags.map((tag) => (
                <span className="tag" key={tag}>
                  {tag}
                </span>
              ))}
            </span>
          )}
        </React.Fragment>
      )}
    </SectionEntryTitleEl>
  );
};

SectionEntryTitle.propTypes = {
  title: PropTypes.string.isRequired,
  titleLink: PropTypes.string,
  tags: PropTypes.array,
  small: PropTypes.bool,
};

export default SectionEntryTitle;

import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageWrapper from '../components/common/page-wrapper';
import Header from '../components/header';
import SectionEntryTitle from '../components/section-entry-title';
import styled from 'styled-components';
import COLORS from '../constants/colors';
import setupItems from '../constants/setupItems';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import useIsClient from '../hooks/useIsClient';
import EmailListSignUp from '../components/EmailListSignUp';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-gap: 1rem;
`;
const GridItem = styled.div`
  p {
    font-size: 0.8rem;
    line-height: 1.5rem;
    color: ${COLORS.darkGrey};
    margin-top: -16px;
  }
`;
const ReadMoreBtn = styled.span`
  color: ${COLORS.baseColor};
  font-weight: bold;
  cursor: pointer;
` 
;

const trackEvent = (setupItem) => {
  if (typeof window === 'undefined') return;

  if (!window && !window.ReactGA)
    return console.warn('React GA not available on global window obeject');

  window.ReactGA.event({
    category: 'User',
    action: `Clicked on affiliate link: ${setupItem.title}`,
    label: `Link: ${setupItem.link}`,
  });
};

// TODO: Add more items, write descriptions and take photos of each item. Use what you have for now
// and for those that don't exist use product images till you can take custom pictures

// TODO: add React GA to track this page.

const SetupItemDescription = ({text, charLimit}) => {
  let [shouldShowFullText, setShouldShowFullText] = React.useState(false);
  let [displayedText, setDisplayedText] = React.useState(text);

  React.useEffect(() => {
    setDisplayedText(shouldShowFullText || text < charLimit ? text : text.slice(0, charLimit) + "...");
  }, [shouldShowFullText])

  return (
    <p>{displayedText} <ReadMoreBtn onClick={_ => setShouldShowFullText((prevState) => !prevState)}>{
      shouldShowFullText ?
      "See Less" : "Read More"
    }</ReadMoreBtn></p>
  )
}

const SetupPage = ({ data }) => {
  const { isClient } = useIsClient();

  const CHAR_LIMIT = 125;

  if (!isClient) return null;
  return (
    <Layout>
      <SEO
        title="My Productivity Setup"
        keywords={[
          `Babs Craig`,
          `Productivity`,
          `Babasanya Craig`,
          `Setup`,
          `Workspace`,
          `Workspace setup`,
          `Software Development`,
          `Content Creation`,
        ]}
      />
      <Header
        headerTitle="My Setup"
        headerText={`I've worked remotely for the greater part of 4 years and
        in that time I've come to realize that having a great workspace setup can have an immense impact on your
        ability to stay productive at home. I've gone through several iterations of my workspace - from a matress in my bedroom to my first office desk setup - and I thought to
        share a few of my favorite items from my current iteration especially as I get asked about them on Instagram.`}
      />
      <PageWrapper>
        <Grid>
          {setupItems
            .sort((a, b) => a.index > b.index)
            .map((setupItem) => (
              <GridItem key={setupItem.index}>
                <Img fluid={data[setupItem.imageKey].childImageSharp.fluid} />
                <span onClick={() => trackEvent(setupItem)}>
                  <SectionEntryTitle
                    title={setupItem.title}
                    titleLink={setupItem.link}
                    small
                  />
                </span>
                <SetupItemDescription text={setupItem.description} charLimit={CHAR_LIMIT} />
              </GridItem>
            ))}
        </Grid>
        <EmailListSignUp />
      </PageWrapper>
    </Layout>
  );
};

export const query = graphql`
  query {
    monitorImage: file(relativePath: { eq: "monitor.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    wallpaperImage: file(relativePath: { eq: "wallpaper.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    keyboardImage: file(relativePath: { eq: "keyboard.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    keycapsImage: file(relativePath: { eq: "keycaps.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    keycapsImage: file(relativePath: { eq: "keycaps.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    mouseImage: file(relativePath: { eq: "mouse.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    laptopstandImage: file(relativePath: { eq: "laptopstand.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    monitorstandImage: file(relativePath: { eq: "monitorstand.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default SetupPage;
